import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: 'https://o.yushengtong.cn/',
    loading: false,
    user: {},
    active: 0,
    my_nav_bar_name: '我的',
    my_nav_bar_icon: 'al-icon-icon_wode',
    identity: 'identity'
  },
  mutations: {
    edit_user(state, data) {
      state.user = data
    },
    false_loading(state) {
      state.loading = false
    },
    true_loading(state) {
      state.loading = true
    },
    edit_active(state,data) {
      state.active = data.active
      state.my_nav_bar_name = data.my_nav_bar_name
      state.my_nav_bar_icon = data.my_nav_bar_icon
    },
    edit_identity(state, data) {
      state.identity = data
    },
  },
  actions: {
  },
  modules: {
  }
})
