import Vue from 'vue'
import VueRouter from 'vue-router'

const MainView = () => import('@/views/MainView')
const IndexView = () => import('@/views/index/IndexView')
const WXLoginView = () => import('@/views/login/WXLoginView')
const RegisterView = () => import('@/views/register/RegisterView')

//我的
import UserInformationRouter from './user/UserInformationRouter'
import UserEditRouter from './user/UserEditRouter'
import UserCardRouter from './user/UserCardRouter'
//登记
import CarDriverCarRouter from './CarDriverCar/CarDriverCarRouter'
import CarDriverAppointRouter from './CarDriverAppoint/CarDriverAppointRouter'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: MainView,
  children:[
    {
      path:'',
      name:'IndexView',
      component:IndexView
    }
  ].concat(
    //我的
    UserInformationRouter,
    UserEditRouter,
    UserCardRouter
  ).concat(
    //车辆管理
    CarDriverCarRouter,
    CarDriverAppointRouter
  )
},{
  path:'/lf_wx_login',
  name:'WXLoginView',
  component:WXLoginView
},{
  path:'/register',
  name:'RegisterView',
  component:RegisterView
}]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//解决重复访问同一个路由报错的问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
    //服务器
    if (to.path === '/register') {
      if (sessionStorage.getItem('x-token')) {
        //如果已经登陆，则跳转到主页
        location.replace('/')
        return
      }
    } else if (to.path === '/lf_wx_login') {
      if (sessionStorage.getItem('x-token')) {
        //如果已经登陆，则跳转到主页
        location.replace('/')
        return
      }
    } else {
      //如果没有登陆，访问其它页面，则调用微信的登陆
      if (!sessionStorage.getItem('x-token')) {
        location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxccdebd924f18ecfc&redirect_uri=https://lfmobile.yushengtong.cn/lf_wx_login&response_type=code&scope=snsapi_base#wechat_redirect')
        return
      }
    }
  next()
})

export default router
