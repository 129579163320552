const CarDriverAppointView = () => import('@/views/CarDriverAppoint/CarDriverAppointView')
const CarDriverAppointDetails = () => import('@/components/CarDriverAppoint/CarDriverAppointDetailsComponent')
const CarDriverAppointAdd = () => import('@/views/CarDriverAppoint/CarDriverAppointAdd')

export default [
    {
        path:'/CarDriverAppointIndex',
        name:'CarDriverAppointIndex',
        component:CarDriverAppointView
    },
    {
        path:'/CarDriverAppointDetails',
        name:'CarDriverAppointDetails',
        component:CarDriverAppointDetails
    },
    {
        path:'/CarDriverAppointAdd',
        name:'CarDriverAppointAdd',
        component:CarDriverAppointAdd
    }
]