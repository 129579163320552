const CarDriverCarView = () => import('@/views/CarDriverCar/CarDriverCarView')
const CarDriverCarDetails = () => import('@/components/CarDriverCar/CarDriverCarDetailsComponent')

export default [
    {
        path:'/CarDriverCarIndex',
        name:'CarDriverCarIndex',
        component:CarDriverCarView
    },
    {
        path:'/CarDriverCarDetails',
        name:'CarDriverCarDetails',
        component:CarDriverCarDetails
    }
]